import { type PbPartnerConfig, PB_PARTNER_CONFIG } from '@/lib/ads/constants/partners'
import { PREBID_CONFIG, PREBID_KEYS } from '@/lib/ads/constants/prebid'

/**
 * Given a particular index/count, add prefix "100" to make a 6-char ID string.
 * The "100" prefix is Ad Ops' convention to avoid naming collisions in GAM.
 * @param count  index value to be converted.
 * @returns {string} bucketString
 */
export const convertToBucketString = (count: number) => `${100000 + Math.floor(count)}`

/**
 * Given a CPM (cost in CAD per 1000 impressions) from a bidding partner,compute/identify the GAM price bucket.
 * IDs are sequential values starting at 1¢ (e.g., "100001" "100002", "100003", ...).
 * The "100" prefix is Ad Ops' convention to avoid naming collisions in GAM.
 * If all price buckets were penny for penny, the ID is simple to derive.
 * For example, a CPM of 59¢ would go in the price bucket "100059"(100000 + 0.59).
 * Above a specified threshold, the buckets are by the nickel.
 * Therefore, an approach similar to income tax bracketing is used.
 * So, we can count increments across the threshold and keep the IDs sequential.
 * Any CPM above $25 is capped at the maximum bucket of "101300".
 * The price bucket IDs and CPM thresholds are devised by Ad Ops.
 * @param {number | string} cpm to be bucketed.
 * @returns {string} customPriceBucket
 */
export const getCustomPriceBucket = (cpm: number | string) => {
  if (typeof cpm === 'string') {
    cpm = parseFloat(cpm)
  }

  if (cpm < PREBID_CONFIG.BRACKET_PENNIES) {
    return convertToBucketString(cpm * PREBID_CONFIG.PENNIES_PER_$)
  }
  if (cpm < PREBID_CONFIG.BRACKET_NICKELS) {
    const pennies = PREBID_CONFIG.BRACKET_PENNIES * PREBID_CONFIG.PENNIES_PER_$
    const count = pennies + (cpm - PREBID_CONFIG.BRACKET_PENNIES) * PREBID_CONFIG.NICKELS_PER_$
    return convertToBucketString(count)
  }
  return PREBID_CONFIG.MAX_BUCKET
}

/**
 * For each defined partner, if CPM adjustment is < 1 then set adjustment function.
 * When adjustment is 1, that partner is already reporting our net of revshare.
 * https://docs.prebid.org/dev-docs/publisher-api-reference/bidderSettings.html
 */
export const getBidCpmAdjustment = (partnerConfig: PbPartnerConfig[]) => {
  return partnerConfig.forEach((partner) => {
    if (partner.cpmAdjustment < 1) {
      window.pbjs.bidderSettings[partner.id] = {
        bidCpmAdjustment: (bidCpm: number) => {
          return bidCpm * partner.cpmAdjustment
        },
      }
    }
  })
}

export const setupPrebid = () => {
  window.pbjs.bidderSettings = {
    standard: {
      adserverTargeting: [
        {
          key: PREBID_KEYS.PREBID_AUCTION_PRICE,
          val: (bid) => getCustomPriceBucket(bid.cpm),
        },
        {
          key: PREBID_KEYS.PREBID_BIDDING_PARTNER,
          val: (bid) => bid.bidderCode,
        },
        {
          key: PREBID_KEYS.PREBID_AD_SIZE,
          val: (bid) => bid.size,
        },
        {
          key: PREBID_KEYS.PREBID_AD_ID,
          val: (bid) => bid.adId,
        },
        {
          key: PREBID_KEYS.PREBID_DEAL_ID,
          val: (bid) => bid.dealId,
        },
        {
          key: PREBID_KEYS.PREBID_TIME_TO_RESPOND,
          val: (bid) => bid.timeToRespond,
        },
        {
          key: PREBID_KEYS.PREBID_AD_SOURCE,
          val: (bid) => bid.source,
        },
        {
          key: PREBID_KEYS.PREBID_AD_FORMAT,
          val: (bid) => bid.mediaType,
        },
      ],
    },
  }

  getBidCpmAdjustment(PB_PARTNER_CONFIG)
}

/**
 * prebidConfig gets passed to the AdvertisingProvider as a plugin and will be executed in that scope/context.
 */
export const prebidConfig = [
  {
    setupPrebid,
  },
]
