// =============================================================================
//
// Adobe Audience Manager constants
//
// =============================================================================

/**
 * Adobe Audience Manager (AAM) configuration used by its script
 */
export const DIL_CONFIG = {
  partner: 'kijiji',
  visitorService: {
    namespace: 'AE159CC25A134C820A495C97@AdobeOrg',
  },
  uuidCookie: { name: 'aam_uuid', days: 30 },
}
