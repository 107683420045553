import { type PAGE_TYPE, PAGE_TYPE_GPT } from '@/constants/pageTypes'
import {
  createHeaderBiddingLabel,
  createOptOutTargetingConfig,
  createPrebidVersionLabel,
  createTestTargetConfig,
  createWebappName,
  createWebVersionTarget,
} from '@/lib/ads/components/gpt/configGpt'
import { createHashedDeviceIdLabel } from '@/lib/ads/components/gpt/deviceGpt'
import { GPT_KEYS } from '@/lib/ads/constants/gpt'
import { type GptTargeting } from '@/lib/ads/types/adConfig'

export const getGenericTargetingKeys = ({
  hasUserOptedOutTargeting,
  pageType,
  ptgValues,
}: {
  hasUserOptedOutTargeting: boolean
  pageType: PAGE_TYPE
  ptgValues: string[]
}): GptTargeting[] => {
  /** General Page Keys */
  const genericKeys: GptTargeting[] = [
    /** DEVICE */
    createHashedDeviceIdLabel(),
    /**
     * These ptg values are related to vcadmin and there is a heap of logic on BOX in Trackable.java for generating them.
     * On legacy, googletag.pubads().getTargeting('ptg') always returns an array of identical values.
     * So, for the time being, we will use hardcoded values.
     */
    { key: GPT_KEYS.PTG_LABEL, value: ptgValues },

    /** PAGE */
    { key: GPT_KEYS.PAGE_TYPE_LABEL, value: pageType },
    { key: GPT_KEYS.PAGE_TYPE_LABEL_KJ, value: PAGE_TYPE_GPT[pageType] },
    createWebappName(),

    /**
     * CONFIG
     */
    createHeaderBiddingLabel(),
    createOptOutTargetingConfig(hasUserOptedOutTargeting),
    createPrebidVersionLabel(),
  ]

  const testTarget = createTestTargetConfig()
  if (testTarget) genericKeys.push(testTarget)

  const webVersionTarget = createWebVersionTarget()
  if (webVersionTarget) genericKeys.push(webVersionTarget)

  return genericKeys
}
