import Script from 'next/script'

import { useScriptOptOut } from '@/hooks/useScriptOptout'
import { DIL_CONFIG } from '@/lib/ads/constants/aam'
import { type GptTargeting } from '@/lib/ads/types/adConfig'
import { flattenGptArray } from '@/lib/ads/utils/globalOptimizationTool'
import { getScriptSrcWithPrefix } from '@/utils/cdn'
import { sendToLogger } from '@/utils/sendToLogger'

type AdobeAudienceManagerProps = {
  gpt: GptTargeting[]
}

/**
 * Script tags and config for Adobe Audience Manager
 * Debugger Tool: https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob?hl=en
 * Documentation: https://experienceleague.adobe.com/docs/audience-manager/user-guide/dil-api/dil-overview.html?lang=en
 * The scripts need to be downloaded asynchronously but executed in order,
 * which doesn't happen reliably if they both use afterInteractive
 *
 * @param gpt - Array of Google Publisher Tag values generated by the useSrpGpt hook
 */

export const AdobeAudienceManager = ({ gpt }: AdobeAudienceManagerProps) => {
  const { shouldRenderScript } = useScriptOptOut()

  if (!shouldRenderScript('adobeAudienceManager')) {
    return null
  }
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={getScriptSrcWithPrefix('/aam/visitorapi-v4.5.2.min.js')}
      />
      <Script
        strategy="lazyOnload"
        data-testid="aam-script"
        src={getScriptSrcWithPrefix('/aam/aam-dil-v9.4.js')}
        onReady={() => {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const kijijiDil = window.DIL.create(DIL_CONFIG)
            kijijiDil.api.signals(flattenGptArray(gpt), 'c_').submit()
          } catch (error) {
            sendToLogger(error, { fingerprint: ['AdobeAudienceManager'] })
          }
        }}
      />
    </>
  )
}
