import { isMobileOnly, isTablet } from 'react-device-detect'

import { GPT_KEYS, PAGE_TEST_GROUP_VALUES } from '@/lib/ads/constants/gpt'
import { getMachIdFromCookie } from '@/lib/ads/utils/gptTargeting'

/**
 * Returns an object with the hashed device ID label key-value pair, using the value returned by
 * the `getMachIdFromCookie` function.
 *
 * @returns {Object} An object containing the hashed device ID label key-value pair.
 */
export function createHashedDeviceIdLabel() {
  return {
    key: GPT_KEYS.HASHED_DEVICE_ID_LABEL,
    value: getMachIdFromCookie(),
  }
}

/**
 * Returns the device type label used by createDeviceLabel.
 *
 * @returns {string} The device type label.
 * @deprecated
 */
export const getGptDeviceType = () => {
  if (isMobileOnly) return 'm'

  if (isTablet) return 't'

  return 'd'
}

/**
 * Returns an object with the device label key-value pair, using the value returned by the
 * `getGptDeviceType` function.
 *
 * @returns {Object} An object containing the device label key-value pair.
 * @deprecated
 */
export function createDeviceLabel() {
  return {
    key: GPT_KEYS.DEVICE_LABEL,
    value: getGptDeviceType(),
  }
}

/**
 * Returns an object with the PTG label key-value pair, using the value of the constant
 * `PAGE_TEST_GROUP_VALUES`.
 *
 * @returns {Object} An object containing the PTG label key-value pair.
 */
export function createPtgLabel() {
  return {
    key: GPT_KEYS.PTG_LABEL,
    value: PAGE_TEST_GROUP_VALUES,
  }
}
