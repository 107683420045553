import { isCarsVehiclesCategory } from '@kijiji/category'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { isUrgentListing } from '@/domain/listings/isUrgentListing'
import { type ListingAttribute, ListingPriceType } from '@/generated/graphql-types'
import { GPT_KEYS, GPT_VALUES } from '@/lib/ads/constants/gpt'
import { type GptTargeting } from '@/lib/ads/types/adConfig'
import { type VIP_Listing } from '@/types/listings'

/**
 * Creates an object containing the search keyword target for Google Publisher Tags (GPT)
 * if the 'keywords' parameter is provided.
 *
 * @function
 * @param {string} [keywords] - Optional search keywords to be used as the value.
 * @returns {Object} An object containing the GPT key and value if 'keywords' is provided, or an empty object otherwise.
 * @example
 *
 * const searchKeywordTarget = createSearchKeywordTarget('couch');
 * console.log(searchKeywordTarget); // { key: 'kw', value: 'couch' }
 *
 * const emptyObject = createSearchKeywordTarget();
 * console.log(emptyObject); // {}
 */
export function createSearchKeywordTarget(keywords?: string) {
  if (keywords) {
    return {
      key: GPT_KEYS.SEARCH_KEYWORD_TARGET,
      value: keywords,
    }
  }
  return {}
}

/**
 * Creates an object containing the category ID for Google Publisher Tags (GPT)
 *
 * @param {number} [categoryId] - The ID of the category to use for the label.
 * @returns {Object} An object containing the category ID label key-value pair, or an empty
 * object if no category ID is provided.
 * @example
 *
 * const result = createCategoryIdLabel(123)
 * console.log(result); // { key: 'ct', value: ['123'] }
 *
 * const result = createCategoryIdLabel()
 * console.log(result); // { }
 */
export function createCategoryIdLabel(categories?: string[]) {
  if (categories?.length) {
    return {
      key: GPT_KEYS.CATEGORY_ID_LABEL,
      value: categories.reverse(),
    }
  }
  return {}
}

/**
 * Returns an object with the location ID label key-value pair if a `locationId` is provided.
 *
 * @param {number} [locationId] - Optional location ID to use for the label.
 * @returns {Object} An object containing the location ID label key-value pair if `locationId` is provided, or an empty object otherwise.
 * @example
 *
 * const locationIdLabel = createLocationIdLabel(42);
 * console.log(locationIdLabel); // { key: 'lc', value: '42' }
 */
export function createLocationIdLabel(locationId: number): GptTargeting {
  return {
    key: GPT_KEYS.LOCATION_ID_LABEL,
    value: `${locationId}`,
  }
}

/**
 * Adds tracking categories to an array of key-value pairs.
 *
 * @param {string[]} categories - An array of tracking categories.
 * @returns {Object[]} An array of key-value pairs representing the tracking categories.
 * @example
 *
 * const categories = ['category1', 'category2', 'category3'];
 * const trackingCategories = addTrackingCategories(categories);
 * console.log(trackingCategories);
 * // [
 * //   { key: 'l1', value: 'category1' },
 * //   { key: 'l2', value: 'category2' },
 * //   { key: 'l3', value: 'category3' }
 * // ]
 * @deprecated
 */
export function addTrackingCategories(categories: Array<string | undefined>): Array<GptTargeting> {
  const keys = [
    GPT_KEYS.LEVEL_ONE_CATEGORY_LABEL,
    GPT_KEYS.LEVEL_TWO_CATEGORY_LABEL,
    GPT_KEYS.LEVEL_THREE_CATEGORY_LABEL,
  ]

  return categories.reduce((acc: Array<GptTargeting>, val, index) => {
    if (val) {
      acc.push({
        key: keys[index],
        value: String(val),
      })
    }
    return acc
  }, [])
}

export const createFeaturesLabel = (
  listingFlags: Record<string, boolean>,
  categoryId: number
): GptTargeting | undefined => {
  const features = []

  if (
    isUrgentListing({
      categorySpecificBadge: !!listingFlags.categorySpecificBadge,
      categoryId,
    })
  ) {
    features.push(GPT_VALUES.URGENT_FEATURE_LABEL)
  }

  if (listingFlags.hpGallery) {
    features.push(GPT_VALUES.HOMEPAGE_GALLERY_FEATURE_LABEL)
  }

  if (listingFlags.topAd) {
    features.push(GPT_VALUES.TOPAD_FEATURE_LABEL)
  }

  if (listingFlags.highlight) {
    features.push(GPT_VALUES.HIGHLIGHT_FEATURE_LABEL)
  }

  if (listingFlags.veteranFriendly) {
    features.push(GPT_VALUES.VETERAN_FRIENDLY_FEATURE_LABEL)
  }

  if (features.length) return { key: GPT_KEYS.FEATURES_LABEL, value: features }
}

export const createForSaleByLabel = (
  forSaleByAttribute: ListingAttribute[]
): GptTargeting | undefined => {
  /** Find for-sale-by attribute */
  const forSaleBy = forSaleByAttribute.find((item) => item.name === 'forsaleby')

  if (!forSaleBy) return

  if (forSaleBy.values[0] === 'owner') {
    return { key: GPT_KEYS.FOR_SALE_BY_LABEL, value: GPT_VALUES.FOR_SALE_BY_OWNER }
  }

  if (forSaleBy.values[0] === 'dealer') {
    return { key: GPT_KEYS.FOR_SALE_BY_LABEL, value: GPT_VALUES.FOR_SALE_BY_BUSINESS }
  }
}

export const createPriceLabel = (
  listingPrice: VIP_Listing['price'],
  categoryId: number
): GptTargeting[] => {
  if (!isAmountPrice(listingPrice)) return []

  /** It should return specific values when price is not fixed for autos */
  if (
    isCarsVehiclesCategory(categoryId) &&
    (listingPrice.amount === null ||
      listingPrice.amount === 0 ||
      listingPrice.type !== ListingPriceType.Fixed)
  ) {
    return [
      { key: GPT_KEYS.PRICE_LABEL, value: '0' },
      { key: GPT_KEYS.PRICE_RANGE_LABEL, value: 'other' },
    ]
  }

  if (listingPrice.type !== ListingPriceType.Fixed) return []
  return [{ key: GPT_KEYS.PRICE_LABEL, value: `${Math.floor(listingPrice.amount)}` }]
}
