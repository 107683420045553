import { type UserStatus } from 'next-auth'

import { type RouteLocale } from '@/domain/locale'
import {
  createHashedUserIdLabel,
  createLanguageLabel,
  createUserLoggedInLabel,
} from '@/lib/ads/components/gpt/userGpt'
import { type GptTargeting } from '@/lib/ads/types/adConfig'

export const getUserTargetingKeys = ({
  userStatus,
  routeLocale,
}: {
  userStatus: UserStatus
  routeLocale: RouteLocale
}): GptTargeting[] => {
  /**
   * User keys
   */
  const userKeys: GptTargeting[] = [
    createUserLoggedInLabel(userStatus),
    createLanguageLabel(routeLocale),
  ]

  const hashedUserLabel = createHashedUserIdLabel()
  hashedUserLabel && userKeys.push(hashedUserLabel)

  return userKeys
}
